// client/src/components/PC1modal
import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import "./ModalForm.css";

function ModalForm({ date, onClose }) {
  const [customer, setCustomer] = useState("");
  const [newbie, setNewbie] = useState("");
  const [running, setRunning] = useState("");
  const [coupon, setCoupon] = useState("");
  const [food, setFood] = useState("");
  const [nonmember, setNonmember] = useState("");
  const [etcexpense, setEtcexpense] = useState("");
  const [cash, setCash] = useState("");
  const [card, setCard] = useState("");
  const [etc, setEtc] = useState("");
  const [memo, setMemo] = useState("");

  // 이미 등록된 데이터인지 확인하기 위한 state
  const [isEditMode, setIsEditMode] = useState(false);

  // 총매출 (TOTALSALES)는 coupon + food + nonmember - etcexpense
  // 입력할 때마다 자동 갱신할 수 있도록 별도 함수로 처리
  const calculateTotalSales = () => {
    const c = parseInt(coupon || 0, 10);
    const f = parseInt(food || 0, 10);
    const n = parseInt(nonmember || 0, 10);
    const e = parseInt(etcexpense || 0, 10);
    return c + f + n - e;
  };

  // 입력 후 초기화
  const resetFields = useCallback((editMode = false) => {
    setCustomer("");
    setNewbie("");
    setRunning("");
    setCoupon("");
    setFood("");
    setNonmember("");
    setEtcexpense("");
    setCash("");
    setCard("");
    setEtc("");
    setMemo("");
    setIsEditMode(editMode);
  }, []);

  const totalsale = calculateTotalSales();

  // (2) 날짜 변경 시 => 데이터 조회
  useEffect(() => {
    if (!date) {
      resetFields(false);
    }
    const fetchDataByDate = async (selectedDate) => {
      try {
        const token = localStorage.getItem("token");
        const response = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}/pcroom2?date=${selectedDate}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.ok) {
          const result = await response.json();
          setCustomer(result.customer ?? "");
          setNewbie(result.newbie ?? "");
          setRunning(result.running ?? "");
          setCoupon(result.coupon ?? "");
          setFood(result.food ?? "");
          setNonmember(result.nonmember ?? "");
          setEtcexpense(result.etcexpense ?? "");
          setCash(result.cash ?? "");
          setCard(result.card ?? "");
          setEtc(result.etc ?? "");
          setMemo(result.memo ?? "");
          setIsEditMode(true);
        } else if (response.status === 404) {
          resetFields(false);
        }
      } catch (err) {
        console.error(err);
      }
    };
    fetchDataByDate(date);
  }, [date, resetFields]);

  // 서버로 전송
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const token = localStorage.getItem("token");
      let requestUrl = `${process.env.REACT_APP_API_ENDPOINT}/pcroom2/${date}`;
      let method = "POST";

      if (isEditMode) {
        method = "PUT";
      }

      const response = await fetch(requestUrl, {
        method: method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          date,
          customer: Number(customer || 0),
          newbie: Number(newbie || 0),
          running: Number(running || 0),
          coupon: Number(coupon || 0),
          food: Number(food || 0),
          nonmember: Number(nonmember || 0),
          etcexpense: Number(etcexpense || 0),
          cash: Number(cash || 0),
          card: Number(card || 0),
          etc: Number(etc || 0),
          memo,
          totalsale,
        }),
      });

      const result = await response.json();

      if (response.ok) {
        if (isEditMode) {
          toast.success("수정되었습니다!");
          alert("수정되었습니다!");
        } else {
          toast.success("DB에 성공적으로 저장되었습니다!");
          alert("DB에 성공적으로 저장되었습니다!");
        }
        resetFields(false);
      } else {
        toast.error("오류가 발생했습니다: " + result.message);
        alert("오류가 발생했습니다: " + result.message);
      }
    } catch (error) {
      console.error(error);
      toast.error("요청 중 에러가 발생했습니다.");
      alert("요청 중 에러가 발생했습니다.");
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="modal-close" onClick={onClose}>
          X
        </button>

        <h2>매출 입력 ({date})</h2>
        <div className="form-container">
          <div className="second-form">
            <div className="one-form">
              <label>고객수</label>
              <input
                type="number"
                pattern="[0-9]*"
                inputmode="numeric"
                value={customer}
                onChange={(e) => setCustomer(e.target.value)}
              />
            </div>
            <div className="one-form">
              <label>신규고객</label>
              <input
                type="number"
                pattern="[0-9]*"
                inputmode="numeric"
                value={newbie}
                onChange={(e) => setNewbie(e.target.value)}
              />
            </div>
            <div className="one-form">
              <label>가동률</label>
              <input
                type="number"
                pattern="[0-9]*"
                inputmode="numeric"
                value={running}
                onChange={(e) => setRunning(e.target.value)}
              />
            </div>
            <div className="one-form">
              <label>쿠폰</label>
              <input
                type="number"
                pattern="[0-9]*"
                inputmode="numeric"
                value={coupon}
                onChange={(e) => setCoupon(e.target.value)}
              />
            </div>
            <div className="one-form">
              <label>음식</label>
              <input
                type="number"
                pattern="[0-9]*"
                inputmode="numeric"
                value={food}
                onChange={(e) => setFood(e.target.value)}
              />
            </div>
            <div className="one-form">
              <label>비회원</label>
              <input
                type="number"
                pattern="[0-9]*"
                inputmode="numeric"
                value={nonmember}
                onChange={(e) => setNonmember(e.target.value)}
              />
            </div>
            <div className="one-form">
              <label>기타지출</label>
              <input
                type="number"
                pattern="[0-9]*"
                inputmode="numeric"
                value={etcexpense}
                onChange={(e) => setEtcexpense(e.target.value)}
              />
            </div>
            <div className="one-form">
              <label>현금</label>
              <input
                type="number"
                pattern="[0-9]*"
                inputmode="numeric"
                value={cash}
                onChange={(e) => setCash(e.target.value)}
              />
            </div>
            <div className="one-form">
              <label>카드</label>
              <input
                type="number"
                pattern="[0-9]*"
                inputmode="numeric"
                value={card}
                onChange={(e) => setCard(e.target.value)}
              />
            </div>
            <div className="one-form">
              <label>비고</label>
              <input
                type="number"
                pattern="[0-9]*"
                inputmode="numeric"
                value={etc}
                onChange={(e) => setEtc(e.target.value)}
              />
            </div>
          </div>

          <label>메모</label>
          <textarea
            rows="3"
            value={memo}
            onChange={(e) => setMemo(e.target.value)}
          />

          <label>총매출(자동 계산)</label>
          <input type="number" value={totalsale} readOnly />

          {/* 버튼: isEditMode에 따라 라벨 변경 */}
          <div className="buttonDiv">
            <button
              className="submit-button"
              type="submit"
              onClick={handleSubmit}
            >
              {isEditMode ? "수정" : "저장"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalForm;
