import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "./ModalForm.css";

function ModalForm({ date, onClose }) {
  // 1) 입력값 State
  const [mainforce, setMainforce] = useState("");
  const [kiosk2, setKiosk2] = useState("");
  const [kiosk3, setKiosk3] = useState("");
  const [deliverycount, setDeliverycount] = useState("");
  const [cash, setCash] = useState("");
  const [account, setAccount] = useState("");
  const [deliverysales, setDeliverysales] = useState("");
  const [cashcount, setCashcount] = useState("");
  const [deliverycharge, setDeliverycharge] = useState("");
  const [coupangbaemin, setCoupangbaemin] = useState("");
  const [forceorder, setForceorder] = useState("");
  const [kiosk12, setKiosk12] = useState("");

  // 2) 계산값 State
  const [totalsales, setTotalsales] = useState("");
  const [totalorder, setTotalorder] = useState("");
  const [cardsales, setCardsales] = useState("");
  const [customercharge, setCustomercharge] = useState("");
  const [hallcharge, setHallcharge] = useState("");
  const [hallorder, setHallorder] = useState("");
  const [arppu, setArppu] = useState("");

  // 수정모드 여부
  const [isEditMode, setIsEditMode] = useState(false);

  // 3) 계산 로직 (mainforce 등은 문자열이므로 Number(...) 변환)
  const calculateAll = () => {
    const mf = Number(mainforce || 0);
    const k2 = Number(kiosk2 || 0);
    const k3 = Number(kiosk3 || 0);
    const dc = Number(deliverycount || 0);
    const c = Number(cash || 0);
    const cbm = Number(coupangbaemin || 0);
    const fd = Number(forceorder || 0);
    const k12 = Number(kiosk12 || 0);
    const dcharge = Number(deliverycharge || 0);

    // 총매출
    const ts = mf + k2 + k3;
    // 총주문
    const to = fd + k12;
    // 카드매출
    const cs = ts - c;
    // 고객부담
    const cCharge = (dc - cbm) * 1500;
    // 매장부담
    const hCharge = dcharge - cCharge;
    // 매장주문건
    const hOrder = to - dc;
    // 객단가
    const a = to !== 0 ? ts / to : 0;

    setTotalsales(ts);
    setTotalorder(to);
    setCardsales(cs);
    setCustomercharge(cCharge);
    setHallcharge(hCharge);
    setHallorder(hOrder);
    setArppu(a);
  };

  // 4) 입력값 바뀔 때마다 자동 계산
  useEffect(() => {
    calculateAll();
    // eslint-disable-next-line
  }, [
    mainforce,
    kiosk2,
    kiosk3,
    deliverycount,
    cash,
    account,
    deliverysales,
    cashcount,
    deliverycharge,
    coupangbaemin,
    forceorder,
    kiosk12,
  ]);

  // 5) 날짜 변경 시 => 서버에서 조회
  useEffect(() => {
    if (!date) {
      resetFields(false);
      return;
    }

    const fetchDataByDate = async () => {
      try {
        const token = localStorage.getItem("token");
        console.log(date);
        const response = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}/dessert39?date=${date}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          // 기존 데이터 있음 => 수정모드, 폼 채워넣기
          setMainforce(data.mainforce ?? "");
          setKiosk2(data.kiosk2 ?? "");
          setKiosk3(data.kiosk3 ?? "");
          setDeliverycount(data.deliverycount ?? "");
          setCash(data.cash ?? "");
          setAccount(data.account ?? "");
          setDeliverysales(data.deliverysales ?? "");
          setCashcount(data.cashcount ?? "");
          setDeliverycharge(data.deliverycharge ?? "");
          setCoupangbaemin(data.coupangbaemin ?? "");
          setForceorder(data.forceorder ?? "");
          setKiosk12(data.kiosk12 ?? "");

          // 계산값들도 세팅
          setTotalsales(data.totalsales ?? "");
          setTotalorder(data.totalorder ?? "");
          setCardsales(data.cardsales ?? "");
          setCustomercharge(data.customercharge ?? "");
          setHallcharge(data.hallcharge ?? "");
          setHallorder(data.hallorder ?? "");
          setArppu(data.arppu ?? "");

          setIsEditMode(true);
        } else if (response.status === 404) {
          // 데이터 없음 => 새 입력모드
          resetFields(false);
          // 날짜만 유지
        } else {
          const errData = await response.json();
          toast.error("조회 중 오류: " + errData.message);
        }
      } catch (error) {
        console.error(error);
        toast.error("서버 에러가 발생했습니다.");
      }
    };

    fetchDataByDate();
  }, [date]);

  // 6) 저장/수정 버튼
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const token = localStorage.getItem("token");
      let requestUrl = `${process.env.REACT_APP_API_ENDPOINT}/dessert39/${date}`;
      let method = "POST";

      if (isEditMode) {
        method = "PUT";
      }

      const bodyData = {
        date,
        mainforce: Number(mainforce || 0),
        kiosk2: Number(kiosk2 || 0),
        kiosk3: Number(kiosk3 || 0),
        deliverycount: Number(deliverycount || 0),
        cash: Number(cash || 0),
        account: Number(account || 0),
        deliverysales: Number(deliverysales || 0),
        cashcount: Number(cashcount || 0),
        deliverycharge: Number(deliverycharge || 0),
        coupangbaemin: Number(coupangbaemin || 0),
        forceorder: Number(forceorder || 0),
        kiosk12: Number(kiosk12 || 0),
        totalsales,
        totalorder,
        cardsales,
        customercharge,
        hallcharge,
        hallorder,
        arppu,
      };

      const response = await fetch(requestUrl, {
        method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(bodyData),
      });

      const result = await response.json();

      if (response.ok && result.success) {
        if (isEditMode) {
          toast.success("수정되었습니다!");
          alert("수정되었습니다!");
        } else {
          toast.success("DB에 성공적으로 저장되었습니다!");
          alert("DB에 성공적으로 저장되었습니다!");
        }
        resetFields(false);
      } else {
        toast.error("오류가 발생했습니다: " + result.message);
        alert("오류가 발생했습니다: " + result.message);
      }
    } catch (error) {
      toast.error("요청 중 에러가 발생했습니다.");
      alert("요청 중 에러가 발생했습니다.");
    }
  };

  // 7) 폼 초기화
  const resetFields = (editMode = false) => {
    setMainforce("");
    setKiosk2("");
    setKiosk3("");
    setDeliverycount("");
    setCash("");
    setAccount("");
    setDeliverysales("");
    setCashcount("");
    setDeliverycharge("");
    setCoupangbaemin("");
    setForceorder("");
    setKiosk12("");

    setTotalsales("");
    setTotalorder("");
    setCardsales("");
    setCustomercharge("");
    setHallcharge("");
    setHallorder("");
    setArppu("");

    setIsEditMode(editMode);
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="modal-close" onClick={onClose}>
          X
        </button>

        <h2>매출입력 ({date})</h2>
        <div className="form-container">
          <div className="second-form">
            <div className="one-form">
              <label>메인포스</label>
              <input
                type="number"
                pattern="[0-9]*"
                inputmode="numeric"
                value={mainforce}
                onChange={(e) => setMainforce(e.target.value)}
              />
            </div>
            <div className="one-form">
              <label>키오스크2</label>
              <input
                type="number"
                pattern="[0-9]*"
                inputmode="numeric"
                value={kiosk2}
                onChange={(e) => setKiosk2(e.target.value)}
              />
            </div>
            <div className="one-form">
              <label>키오스크3</label>
              <input
                type="number"
                pattern="[0-9]*"
                inputmode="numeric"
                value={kiosk3}
                onChange={(e) => setKiosk3(e.target.value)}
              />
            </div>
            <div className="one-form">
              <label>배달주문건</label>
              <input
                type="number"
                pattern="[0-9]*"
                inputmode="numeric"
                value={deliverycount}
                onChange={(e) => setDeliverycount(e.target.value)}
              />
            </div>
            <div className="one-form">
              <label>현금매출</label>
              <input
                type="number"
                pattern="[0-9]*"
                inputmode="numeric"
                value={cash}
                onChange={(e) => setCash(e.target.value)}
              />
            </div>
            <div className="one-form">
              <label>계좌이체</label>
              <input
                type="number"
                pattern="[0-9]*"
                inputmode="numeric"
                value={account}
                onChange={(e) => setAccount(e.target.value)}
              />
            </div>
            <div className="one-form">
              <label>배달매출</label>
              <input
                type="number"
                pattern="[0-9]*"
                inputmode="numeric"
                value={deliverysales}
                onChange={(e) => setDeliverysales(e.target.value)}
              />
            </div>
            <div className="one-form">
              <label>만나서 현금건</label>
              <input
                type="number"
                pattern="[0-9]*"
                inputmode="numeric"
                value={cashcount}
                onChange={(e) => setCashcount(e.target.value)}
              />
            </div>
            <div className="one-form">
              <label>배달료</label>
              <input
                type="number"
                pattern="[0-9]*"
                inputmode="numeric"
                value={deliverycharge}
                onChange={(e) => setDeliverycharge(e.target.value)}
              />
            </div>
            <div className="one-form">
              <label>쿠팡잇츠+배민 건수</label>
              <input
                type="number"
                pattern="[0-9]*"
                inputmode="numeric"
                value={coupangbaemin}
                onChange={(e) => setCoupangbaemin(e.target.value)}
              />
            </div>
            <div className="one-form">
              <label>포스주문건</label>
              <input
                type="number"
                pattern="[0-9]*"
                inputmode="numeric"
                value={forceorder}
                onChange={(e) => setForceorder(e.target.value)}
              />
            </div>
            <div className="one-form">
              <label>키오스크주문건</label>
              <input
                type="number"
                pattern="[0-9]*"
                inputmode="numeric"
                value={kiosk12}
                onChange={(e) => setKiosk12(e.target.value)}
              />
            </div>
          </div>
          <hr />

          <label>총매출 (totalsales)</label>
          <input type="number" readOnly value={totalsales} />

          <label>총주문건 (totalorder)</label>
          <input type="number" readOnly value={totalorder} />

          <label>카드매출 (cardsales)</label>
          <input type="number" readOnly value={cardsales} />

          <label>고객부담 (customercharge)</label>
          <input type="number" readOnly value={customercharge} />

          <label>매장부담 (hallcharge)</label>
          <input type="number" readOnly value={hallcharge} />

          <label>매장주문건 (hallorder)</label>
          <input type="number" readOnly value={hallorder} />

          <label>객단가 (arppu)</label>
          <input type="number" readOnly value={arppu} />

          <div className="buttonDiv">
            <button
              className="submit-button"
              type="button"
              onClick={handleSubmit}
            >
              {isEditMode ? "수정" : "저장"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalForm;
