// client/src/pages/AdminSearch.js
import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "./AdminSearch.css";

function AdminSearch() {
  const navigate = useNavigate();

  const [startDate, setStartDate] = useState("2025-01-01");
  const [endDate, setEndDate] = useState("");

  const [searchResults, setSearchResults] = useState([]);

  // 오늘 날짜 (YYYY-MM-DD) 계산
  const today = (() => {
    const d = new Date();
    const yyyy = d.getFullYear();
    const mm = String(d.getMonth() + 1).padStart(2, "0");
    const dd = String(d.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  })();

  useEffect(() => {
    // 기본적으로 endDate = 오늘
    setEndDate(today);
  }, [today]);

  // 한주 검색: 오늘 ~ 7일 전
  const handleOneWeek = () => {
    const d = new Date();
    const end = d.toISOString().slice(0, 10); // 오늘
    d.setDate(d.getDate() - 7); // 7일 전
    const start = d.toISOString().slice(0, 10);

    setStartDate(start);
    setEndDate(end);
  };

  // 한달 검색: 오늘 ~ 30일 전
  const handleOneMonth = () => {
    const d = new Date();
    const end = d.toISOString().slice(0, 10); // 오늘
    d.setDate(d.getDate() - 30); // 30일 전
    const start = d.toISOString().slice(0, 10);

    setStartDate(start);
    setEndDate(end);
  };

  // "검색" 버튼 클릭 시 -> 서버에 fetch
  const handleSearch = useCallback(async () => {
    if (!startDate || !endDate) {
      return;
    }
    // 백엔드로 검색 요청
    try {
      // 예: GET /api/admin/search?start=YYYY-MM-DD&end=YYYY-MM-DD
      // 실제 라우트는 원하는 대로 설계
      const token = localStorage.getItem("token");
      const query = `start=${startDate}&end=${endDate}`;
      const res = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/admin/search?${query}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await res.json();
      if (data.success) {
        // data.results = [{storeName, latestSale, missingDates:[]}, ...]
        setSearchResults(data.results);
      } else {
        alert("검색 실패: " + data.message);
      }
    } catch (err) {
      console.error(err);
      alert("검색 중 에러가 발생했습니다.");
    }
  }, [endDate, startDate]);

  // 미입력된 날짜 클릭 시 -> 해당 매장 + 날짜 입력창 이동
  const handleDateClick = useCallback(
    (storeName, date) => {
      if (storeName === "SGNC") {
        storeName = "seoga";
      }
      // 예: navigate(`/pcroom1?date=2024-01-05`) 등등
      // 스토어명 -> pcroom1 / dessert39 / etc ...
      // 실제 매핑 로직은 프로젝트 구조에 따라
      navigate(`/${storeName}?date=${date}`);
    },
    [navigate]
  );

  // "자동검색" 수행 후, 만약 검색결과 첫 매장에 미입력 날짜가 있으면 handleDateClick
  const autoSearchAndNavigate = useCallback(async () => {
    await handleSearch();
  }, [handleSearch]);
  // --- (1) 페이지 로드 시 "검색" 자동 실행 ---
  useEffect(() => {
    autoSearchAndNavigate();
  }, [autoSearchAndNavigate]);

  return (
    <div className="admin-search-container">
      <h2>매출관리</h2>
      <div className="search-form">
        <div className="date-inputs">
          <label>시작일</label>
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            min="2024-01-01"
          />
          <label>종료일</label>
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            min="2024-01-01"
          />
        </div>

        <div className="quick-buttons">
          <button onClick={handleOneWeek}>한 주 검색</button>
          <button onClick={handleOneMonth}>한 달 검색</button>
          <button onClick={handleSearch}>날짜검색</button>
        </div>
      </div>

      {/* 검색 결과 표 */}
      <div className="admin-users-table-wrapper">
        <table className="admin-result-table">
          <thead>
            <tr>
              <th>매장명</th>
              <th className="recent">최근 매출</th>
              <th className="noinput">미입력</th>
              <th>미입력날짜</th>
            </tr>
          </thead>
          <tbody>
            {searchResults.map((row, idx) => {
              const { storeName, latestSale, missingDates } = row;
              return (
                <tr key={idx}>
                  <td
                    className="storename"
                    onClick={() => handleDateClick(storeName, today)}
                  >
                    {storeName}
                  </td>
                  <td>{latestSale.toLocaleString()}원</td>
                  <td>{missingDates.length}</td>
                  <td>
                    {missingDates.length === 0 ? (
                      <span>없음</span>
                    ) : (
                      missingDates.map((md, i) => (
                        <button
                          key={i}
                          className="missing-date-btn"
                          onClick={() => handleDateClick(storeName, md)}
                        >
                          {md}
                        </button>
                      ))
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default AdminSearch;
