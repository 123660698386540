// src/PublicRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';

function PublicRoute({ children }) {
  // localStorage에 토큰이 있으면 "로그인된 상태"라고 가정
  const token = localStorage.getItem('token');

  if (token) {
    // 로그인되어 있으면 메인 페이지 등 다른 곳으로 돌려보냄
    return <Navigate to="/" replace />;
  }

  // 로그인 안 된 상태라면, 해당 페이지(로그인/회원가입 등)를 보여줌
  return children;
}

export default PublicRoute;