import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "./ModalForm.css";

function ModalForm({ date, onClose }) {
  const [ordercount, setOrdercount] = useState("");
  const [cash, setCash] = useState("");
  const [card, setCard] = useState("");
  const [online, setOnline] = useState("");
  const [etc, setEtc] = useState("");
  const [account, setAccount] = useState("");
  const [mobile, setMobile] = useState("");
  const [delivery, setDelivery] = useState("");
  const [deliverycount, setDeliverycount] = useState("");
  const [deliverycharge, setDeliverycharge] = useState("");
  const [customercharge, setCustomercharge] = useState("");
  const [hallcharge, setHallcharge] = useState("");
  const [baemin, setBaemin] = useState("");
  const [yogiyo, setYogiyo] = useState("");
  const [baemin1, setBaemin1] = useState("");
  const [coupang, setCoupang] = useState("");
  const [memo, setMemo] = useState("");

  // (B) 계산값 State (예: totalsales)
  const [totalsales, setTotalsales] = useState("");

  // (C) 수정 모드 여부
  const [isEditMode, setIsEditMode] = useState(false);

  // (1) 자동 계산 로직
  // 총매출 = cash + card + online + etc + account + mobile
  // (delivery 는 정책에 따라 합산 여부 결정)
  const calculateTotalsales = () => {
    const c = Number(cash || 0);
    const d = Number(card || 0);
    const o = Number(online || 0);
    const e = Number(etc || 0);
    const a = Number(account || 0);
    const m = Number(mobile || 0);
    return c + d + o + e + a + m;
  };

  // (2) 입력값 변화 시 totalsales 재계산
  useEffect(() => {
    const ts = calculateTotalsales();
    setTotalsales(ts);
    // eslint-disable-next-line
  }, [cash, card, online, etc, account, mobile]);

  // (3) 날짜가 바뀔 때 -> 서버로 GET /bodram1?date=...
  useEffect(() => {
    if (!date) {
      resetFields(false);
      return;
    }

    const fetchDataByDate = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}/bodram1?date=${date}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.ok) {
          // 기존 데이터 있음 => 수정 모드
          const data = await response.json();
          setOrdercount(data.ordercount ?? "");
          setCash(data.cash ?? "");
          setCard(data.card ?? "");
          setOnline(data.online ?? "");
          setEtc(data.etc ?? "");
          setAccount(data.account ?? "");
          setMobile(data.mobile ?? "");
          setDelivery(data.delivery ?? "");
          setDeliverycount(data.deliverycount ?? "");
          setDeliverycharge(data.deliverycharge ?? "");
          setCustomercharge(data.customercharge ?? "");
          setHallcharge(data.hallcharge ?? "");
          setBaemin(data.baemin ?? "");
          setYogiyo(data.yogiyo ?? "");
          setBaemin1(data.baemin1 ?? "");
          setCoupang(data.coupang ?? "");
          setMemo(data.memo ?? "");
          setTotalsales(data.totalsales ?? "");
          setIsEditMode(true);
        } else if (response.status === 404) {
          // 데이터 없음 => 새 입력 모드
          resetFields(false);
        } else {
          // 기타 오류
          const errData = await response.json();
          toast.error("조회 중 오류: " + errData.message);
        }
      } catch (error) {
        console.error(error);
        toast.error("서버 에러가 발생했습니다.");
      }
    };

    fetchDataByDate();
  }, [date]);

  // (4) 저장/수정 버튼
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("token");
      let url = `${process.env.REACT_APP_API_ENDPOINT}/bodram1/${date}`;
      let method = "POST";

      if (isEditMode) {
        method = "PUT";
      }

      const bodyData = {
        date,
        ordercount: Number(ordercount || 0),
        cash: Number(cash || 0),
        card: Number(card || 0),
        online: Number(online || 0),
        etc: Number(etc || 0),
        account: Number(account || 0),
        mobile: Number(mobile || 0),
        delivery: Number(delivery || 0),
        deliverycount: Number(deliverycount || 0),
        deliverycharge: Number(deliverycharge || 0),
        customercharge: Number(customercharge || 0),
        hallcharge: Number(hallcharge || 0),
        baemin: Number(baemin || 0),
        yogiyo: Number(yogiyo || 0),
        baemin1: Number(baemin1 || 0),
        coupang: Number(coupang || 0),
        memo,
        totalsales: Number(totalsales || 0),
      };

      const response = await fetch(url, {
        method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(bodyData),
      });

      const result = await response.json();
      if (response.ok && result.success) {
        if (isEditMode) {
          toast.success("수정되었습니다!");
          alert("수정되었습니다!");
        } else {
          toast.success("DB에 성공적으로 저장되었습니다!");
          alert("DB에 성공적으로 저장되었습니다!");
        }
        resetFields(false);
        onClose();
      } else {
        toast.error("오류가 발생했습니다: " + result.message);
        alert("오류가 발생했습니다: " + result.message);
      }
    } catch (error) {
      console.error(error);
      toast.error("요청 중 에러가 발생했습니다.");
      alert("요청 중 에러가 발생했습니다.");
    }
  };

  // (5) 폼 초기화
  const resetFields = (editMode = false) => {
    setOrdercount("");
    setCash("");
    setCard("");
    setOnline("");
    setEtc("");
    setAccount("");
    setMobile("");
    setDelivery("");
    setDeliverycount("");
    setDeliverycharge("");
    setCustomercharge("");
    setHallcharge("");
    setBaemin("");
    setYogiyo("");
    setBaemin1("");
    setCoupang("");
    setMemo("");
    setTotalsales("");
    setIsEditMode(editMode);
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="modal-close" onClick={onClose}>
          X
        </button>
        <h2>매출 입력 ({date})</h2>

        <div className="form-container">
          <div className="second-form">
            <div className="one-form">
              <label>주문수</label>

              <input
                type="number"
                pattern="[0-9]*"
                inputmode="numeric"
                value={ordercount}
                onChange={(e) => setOrdercount(e.target.value)}
              />
            </div>
            <div className="one-form">
              <label>현금</label>
              <input
                type="number"
                pattern="[0-9]*"
                inputmode="numeric"
                value={cash}
                onChange={(e) => setCash(e.target.value)}
              />
            </div>
            <div className="one-form">
              <label>카드</label>
              <input
                type="number"
                pattern="[0-9]*"
                inputmode="numeric"
                value={card}
                onChange={(e) => setCard(e.target.value)}
              />
            </div>
            <div className="one-form">
              <label>온라인선결제</label>
              <input
                type="number"
                pattern="[0-9]*"
                inputmode="numeric"
                value={online}
                onChange={(e) => setOnline(e.target.value)}
              />
            </div>
            <div className="one-form">
              <label>기타매출</label>
              <input
                type="number"
                pattern="[0-9]*"
                inputmode="numeric"
                value={etc}
                onChange={(e) => setEtc(e.target.value)}
              />
            </div>
            <div className="one-form">
              <label>계좌이체</label>
              <input
                type="number"
                pattern="[0-9]*"
                inputmode="numeric"
                value={account}
                onChange={(e) => setAccount(e.target.value)}
              />
            </div>
            <div className="one-form">
              <label>모바일</label>
              <input
                type="number"
                pattern="[0-9]*"
                inputmode="numeric"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
              />
            </div>
            <div className="one-form">
              <label>배달매출</label>
              <input
                type="number"
                pattern="[0-9]*"
                inputmode="numeric"
                value={delivery}
                onChange={(e) => setDelivery(e.target.value)}
              />
            </div>
            <div className="one-form">
              <label>배달주문건</label>
              <input
                type="number"
                pattern="[0-9]*"
                inputmode="numeric"
                value={deliverycount}
                onChange={(e) => setDeliverycount(e.target.value)}
              />
            </div>
            <div className="one-form">
              <label>배달료</label>
              <input
                type="number"
                pattern="[0-9]*"
                inputmode="numeric"
                value={deliverycharge}
                onChange={(e) => setDeliverycharge(e.target.value)}
              />
            </div>
            <div className="one-form">
              <label>고객부담</label>
              <input
                type="number"
                pattern="[0-9]*"
                inputmode="numeric"
                value={customercharge}
                onChange={(e) => setCustomercharge(e.target.value)}
              />
            </div>
            <div className="one-form">
              <label>매장부담</label>
              <input
                type="number"
                pattern="[0-9]*"
                inputmode="numeric"
                value={hallcharge}
                onChange={(e) => setHallcharge(e.target.value)}
              />
            </div>
            <div className="one-form">
              <label>배민</label>
              <input
                type="number"
                pattern="[0-9]*"
                inputmode="numeric"
                value={baemin}
                onChange={(e) => setBaemin(e.target.value)}
              />
            </div>
            <div className="one-form">
              <label>요기요</label>
              <input
                type="number"
                pattern="[0-9]*"
                inputmode="numeric"
                value={yogiyo}
                onChange={(e) => setYogiyo(e.target.value)}
              />
            </div>
            <div className="one-form">
              <label>배민1</label>
              <input
                type="number"
                pattern="[0-9]*"
                inputmode="numeric"
                value={baemin1}
                onChange={(e) => setBaemin1(e.target.value)}
              />
            </div>
            <div className="one-form">
              <label>쿠팡</label>
              <input
                type="number"
                pattern="[0-9]*"
                inputmode="numeric"
                value={coupang}
                onChange={(e) => setCoupang(e.target.value)}
              />
            </div>
          </div>

          <label>메모</label>
          <textarea
            rows="3"
            value={memo}
            onChange={(e) => setMemo(e.target.value)}
          />

          <label>총매출(자동 계산)</label>
          <input
            type="number"
            pattern="[0-9]*"
            inputmode="numeric"
            readOnly
            value={totalsales}
          />

          <div className="buttonDiv">
            <button
              className="submit-button"
              type="submit"
              onClick={handleSubmit}
            >
              {isEditMode ? "수정하기" : "저장"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalForm;
