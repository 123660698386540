// src/pages/NoPermission.js
import React from "react";
import { useNavigate } from "react-router-dom";
import "./NoPermission.css";

function NoPermission() {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(1); // 뒤로가기
  };

  return (
    <div className="no-permission-container">
      <h2>권한이 없습니다.</h2>
      <p>이 페이지에 접근할 수 있는 권한이 없습니다.</p>
      <button className="back-button" onClick={handleGoBack}>
        돌아가기
      </button>
    </div>
  );
}

export default NoPermission;
