// client/src/components/Navbar.js
import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  // 햄버거 아이콘 클릭 시 -> 사이드 메뉴 토글
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="admin-navbar">
      {/* 데스크톱 네비게이션 */}
      <div className="navbar-desktop">
        {/* 왼쪽 로고 or Title */}
        <div className="navbar-logo">
          <Link to="/admin">매출관리자페이지</Link>
        </div>

        {/* 오른쪽 메뉴들 (데스크톱) */}
        <ul className="navbar-menu">
          <li>
            <Link to="/admin/sales">매출입력</Link>
          </li>
          <li>
            <Link to="/admin/users">회원관리</Link>
          </li>
        </ul>
      </div>

      {/* 모바일: 햄버거 아이콘 */}
      <div className="navbar-mobile">
        <div className="navbar-logo">
          <Link to="/admin">관리자페이지</Link>
        </div>
        <button className="hamburger-btn" onClick={toggleMenu}>
          {/* 간단한 햄버거 아이콘 (3줄) */}
          <span className="bar" />
          <span className="bar" />
          <span className="bar" />
        </button>
      </div>

      {/* 모바일 슬라이드 메뉴 */}
      <div className={`mobile-menu-slide ${isOpen ? "open" : ""}`}>
        <button className="close-btn" onClick={toggleMenu}>
          ×
        </button>
        <div>
          <ul className="mobile-menu-list">
            <li>
              <Link to="/admin/sales" onClick={toggleMenu}>
                매출입력
              </Link>
            </li>
            <li>
              <Link to="/admin/users" onClick={toggleMenu}>
                회원관리
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
