import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./StorePage.css";
import MonthlyCalendar from "./MonthlyCalendar";
import PC1modal from "../components/PC1modal";
import Navbar from "../components/Navbar";

function Pcroom1() {
  const navigate = useNavigate();
  const [selectedDate, setDate] = useState("");
  const [showModal, setShowModal] = useState(false);
  const token = localStorage.getItem("token");
  const [userName, setUserName] = useState("");
  useEffect(() => {
    const fetchUserInfo = async () => {
      if (!token) {
        navigate("/login");
        return;
      } // 토큰 없으면 로그인 안 된 상태 -> 패스
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}/auth/me`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        if (data.success) {
          // 사용자 이름 추출
          setUserName(data.user.name);
        } else {
          console.error("사용자 정보 조회 실패:", data.message);
          localStorage.removeItem("token");
        }
      } catch (error) {
        console.error("사용자 정보 조회 중 에러:", error);
      }
    };
    fetchUserInfo();
  }, [token, navigate]);

  const handleDateSelect = (ymd) => {
    setDate(ymd);
    setShowModal(true);
    document.body.style.overflow = "hidden";
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  return (
    <div className="main-container">
      {/* useName이 admin이면 아래 블록을 표시 */}
      {userName === "통합관리자" && (
        <div>
          <img
            src="/harmony_logo.png"
            className="logo"
            alt="harmony_logo"
          ></img>
          <div className="admin-menu-container">
            <Navbar />
          </div>
        </div>
      )}

      <div className="container">
        <h2>아이센스리그 전주</h2>
        <div className="user-info">
          <span className="user-name">{userName}님</span>
          <button className="logout-button" onClick={handleLogout}>
            <img src="/logout.svg" alt="logout_button"></img>
            <span>로그아웃</span>
          </button>
        </div>

        <MonthlyCalendar
          onDateSelect={handleDateSelect}
          selectedTableName="PCroom1"
        />

        {showModal && (
          <PC1modal date={selectedDate} onClose={() => setShowModal(false)} />
        )}
      </div>
    </div>
  );
}

export default Pcroom1;
