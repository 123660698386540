// client/src/components/ProgressBar.js
import React from "react";
import "./ProgressBar.css";

function ProgressBar({ goal, thisMonthSum, prevMonthSum }) {
  // 예: 이달 누적 3,000,000 / 목표 10,000,000 => 30%
  const thisPercent = goal ? Math.floor((thisMonthSum / goal) * 100) : 0;
  // 지난달
  const prevPercent = goal ? Math.floor((prevMonthSum / goal) * 100) : 0;

  return (
    <div className="sales-progress-container">
      <div className="progress-text">
        <div>
          <div>이달누적(노란색)</div>
          <div>
            {Number(thisMonthSum).toLocaleString()}({thisPercent}%)
          </div>
        </div>
        <div>
          <div>지난달(회색)</div>
          <div>
            {Number(prevMonthSum).toLocaleString()}({prevPercent}%)
          </div>
        </div>
        <div className="target-amount">월목표 {goal.toLocaleString()}원</div>
      </div>

      {/* Stacked bar: prevMonth (회색) + thisMonth (노랑) */}
      <div className="stacked-bar">
        {/* 지난달 */}
        <div
          className="bar last-month"
          style={{ width: `${prevPercent}%` }}
        ></div>
        {/* 이번달 */}
        <div
          className="bar this-month"
          style={{ width: `${thisPercent}%` }}
        ></div>
      </div>
    </div>
  );
}

export default ProgressBar;
