// src/PrivateRoute.js
import React from "react";
import { Navigate } from "react-router-dom";
import NoPermission from "./pages/NoPermission";
import { jwtDecode } from "jwt-decode";

// props:
// - children: 보호하려는 실제 페이지 컴포넌트
// - requiredRole: 이 페이지에 필요한 role 값
function PrivateRoute({ children, requiredRole }) {
  // localStorage에 저장된 user 정보(JSON)를 가져옴
  const storedToken = localStorage.getItem("token");
  let role = null;
  if (!storedToken) {
    // user 정보가 없으면 로그인 안 된 상태 -> 로그인 페이지로
    return <Navigate to="/login" replace />;
  } else {
    const decoded = jwtDecode(storedToken);
    role = decoded.role;
  }

  // 총관리자(role=49)는 모든 페이지 접근 가능하다고 가정
  // 또는 requiredRole이 undefined면 누구나 접근
  if (requiredRole !== undefined) {
    // requiredRole이 있는데, user.role이 그 값도 아니고 49도 아니라면 -> 권한 없음
    if (role !== requiredRole && role !== 49) {
      return <NoPermission />;
    }
  }

  // 조건 만족 시 children 렌더
  return children;
}

export default PrivateRoute;
