// client/src/components/AdminStatic.js

import React, { useMemo, useState, useEffect } from "react";
import "./AdminStatic.css";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Brush,
} from "recharts";

function AdminStatic() {
  // 테이블 선택 (매장)
  const [tableName, setTableName] = useState("PCroom1");

  // 사용할 수 있는 컬럼들 (실제 상황에 맞춰 조절)
  // 예: totalsales, coupon, food, nonmember, etc...

  const allColumns = useMemo(() => {
    if (tableName === "PCroom1") {
      return [
        "totalsales",
        "customer",
        "newbie",
        "coupon",
        "food",
        "nonmember",
        "card",
        "cash",
      ];
    } else if (tableName === "PCroom2") {
      return [
        "totalsales",
        "customer",
        "newbie",
        "coupon",
        "food",
        "nonmember",
        "card",
        "cash",
      ];
    } else if (tableName === "Dessert39") {
      return ["totalsales", "cardsales", "cash", "deliverysales"];
    } else if (tableName === "GJtteok") {
      return ["totalsales", "cash", "card", "online", "delivery"];
    } else if (tableName === "SGNC") {
      return ["totalsales", "cash", "card", "coupon", "delivery"];
    } else if (tableName === "bodram1") {
      return ["totalsales", "cash", "card"];
    } else if (tableName === "photoism") {
      return ["totalsales", "cash", "card"];
    }
  }, [tableName]);

  // 체크박스로 선택된 컬럼들
  const [selectedCols, setSelectedCols] = useState([]);

  // 가져온 데이터 (date, totalsales, coupon, ...)
  const [chartData, setChartData] = useState([]);
  const recentData = chartData.slice(-7);
  // 브러시가 기본으로 보여줄 구간 (startIndex, endIndex)
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(0);
  // 그래프용 날짜 범위 등 (여기서는 간단히 전부 표시)
  // 실제로는 startDate ~ endDate 필드로 관리 가능
  // 혹은 fetch 시 백엔드에 ?start=...&end=... 보낼 수도 있음
  const isMobile = window.innerWidth <= 768;
  // 초기 로딩: 7일치 데이터 가져오기
  useEffect(() => {
    if (allColumns.length > 0) {
      setSelectedCols([allColumns[0]]);
    } else {
      setSelectedCols([]);
    }
    fetchData(tableName);
  }, [tableName, allColumns]);

  // 서버에서 데이터 불러오기
  const fetchData = async (table) => {
    try {
      // 예: GET /api/admin/stats?table=pcroom1&range=7
      const token = localStorage.getItem("token");
      const res = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/admin/stats?table=${table}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await res.json();
      if (data.success) {
        // data.results = [{ date:'2024-01-01', totalsales:300000, coupon:50000, ... }, ...]
        const rows = data.results;
        setChartData(rows);

        const len = rows.length;
        if (len > 30) {
          setStartIndex(len - 7);
          setEndIndex(len - 1);
        } else {
          setStartIndex(0);
          setEndIndex(len - 1);
        }
      } else {
        alert("데이터 불러오기 실패: " + data.message);
      }
    } catch (err) {
      console.error(err);
      alert("데이터 로딩 중 에러 발생");
    }
  };

  // 체크박스 토글
  const handleCheckboxChange = (col) => {
    if (selectedCols.includes(col)) {
      // 이미 선택된 경우 -> 해제
      setSelectedCols(selectedCols.filter((c) => c !== col));
    } else {
      // 추가
      setSelectedCols([...selectedCols, col]);
    }
  };

  // "오늘 날짜로 이동" 버튼 (Brush를 최신 데이터로 스크롤)
  // Recharts Brush는 programmatic scroll이 좀 복잡함
  // 여기선 간단히 "끝쪽 데이터로 자연스럽게 이동" 예시
  const handleMoveToToday = () => {
    if (chartData.length > 7) {
      setStartIndex(chartData.length - 7);
      setEndIndex(chartData.length - 1);
    }
  };

  return (
    <div className="admin-static-container">
      <h2>매출표 & 그래프</h2>

      {/* 테이블(매장) 드롭다운 */}
      <div className="top-controls">
        <label>매장 선택: </label>
        <select
          value={tableName}
          onChange={(e) => setTableName(e.target.value)}
        >
          <option value="PCroom1">PCroom1</option>
          <option value="PCroom2">PCroom2</option>
          <option value="Dessert39">Dessert39</option>
          <option value="SGNC">Seoga</option>
          <option value="GJtteok">Gjtteok</option>
          <option value="bodram1">Bodram1</option>
          <option value="photoism">Photoism</option>
        </select>

        {/* "오늘로 이동" 버튼 */}
        <button onClick={handleMoveToToday}>오늘날짜로 이동</button>
      </div>

      {/* 체크박스로 column 선택 */}
      <div className="column-checks">
        {allColumns.map((col) => (
          <label key={col} className="check-box">
            <input
              type="checkbox"
              checked={selectedCols.includes(col)}
              onChange={() => handleCheckboxChange(col)}
            />
            <span>{col}</span>
          </label>
        ))}
      </div>

      {/* 그래프 (Recharts) */}
      <div className="chart-wrapper">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={chartData}
            margin={{ top: 30, right: 30, left: 20, bottom: 10 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis
              orientation="left"
              tickLine={false}
              tick={isMobile ? { dx: 10 } : { dx: -10 }}
              hide={isMobile ? true : false}
            />
            <Tooltip />
            <Legend />
            {/* 체크된 컬럼마다 Line 생성 */}
            {selectedCols.map((col, idx) => (
              <Line
                key={col}
                type="monotone"
                dataKey={col}
                stroke={getColorByIndex(idx)} // 각 라인마다 다른 색
                strokeWidth={2}
                dot={{ r: 3 }}
                activeDot={{ r: 6 }} // hover 시 포인트 강조
              />
            ))}

            {/* Brush (밑에 미니맵 / 스크롤) */}
            <Brush
              dataKey="date"
              height={30}
              stroke="#8884d8"
              startIndex={startIndex}
              endIndex={endIndex}
              onChange={(brushRange) => {
                //   brushRange = { startIndex: number, endIndex: number };
                setStartIndex(brushRange.startIndex);
                setEndIndex(brushRange.endIndex);
              }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
      <h3>최근 일주일 데이터</h3>
      {/* 간단한 표도 추가 가능(옵션): date + selectedCols */}
      <div className="admin-users-table-wrapper">
        <table className="simple-table">
          <thead>
            <tr>
              <th>날짜</th>
              {selectedCols.map((col) => (
                <th key={col}>{col}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {recentData.map((item, i) => (
              <tr key={i}>
                <td>{item.date}</td>
                {selectedCols.map((col) => (
                  <td key={col}>{(item[col] || 0).toLocaleString()}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

// 간단히 인덱스로 색상 지정
function getColorByIndex(idx) {
  const colors = [
    "#8884d8",
    "#82ca9d",
    "#ff7300",
    "#ffc658",
    "#d84f8c",
    "#4fd8d8",
  ];
  return colors[idx % colors.length];
}

export default AdminStatic;
