// client/src/components/PC1modal
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "./ModalForm.css";

function ModalForm({ date, onClose }) {
  // (A) 입력값 state
  const [tables, setTables] = useState("");
  const [visittable, setVisittable] = useState("");
  const [cash, setCash] = useState("");
  const [card, setCard] = useState("");
  const [coupon, setCoupon] = useState("");
  const [delivery, setDelivery] = useState("");
  const [deliverycount, setDeliverycount] = useState("");
  const [deliverycharge, setDeliverycharge] = useState("");
  const [memo, setMemo] = useState("");

  // (B) 계산값 state
  const [running, setRunning] = useState("");
  const [totalsales, setTotalsales] = useState("");

  // (C) 수정 모드 여부
  const [isEditMode, setIsEditMode] = useState(false);

  // (1) 계산 로직
  // 가동률 = 방문테이블 / 테이블수
  const calculateRunning = () => {
    const t = Number(tables || 0);
    const v = Number(visittable || 0);
    if (t === 0) return 0;
    return v / t; // 소수점 나올 수 있음
  };

  // 총매출 = cash + card + coupon + delivery
  const calculateTotalSales = () => {
    const c = Number(cash || 0);
    const d = Number(card || 0);
    const p = Number(coupon || 0);
    const dv = Number(delivery || 0);
    return c + d + p + dv;
  };

  // (2) useEffect로 입력값이 바뀔 때마다 자동 계산
  useEffect(() => {
    const run = calculateRunning();
    setRunning(run);

    const ts = calculateTotalSales();
    setTotalsales(ts);
    // eslint-disable-next-line
  }, [tables, visittable, cash, card, coupon, delivery]);

  // (3) 날짜가 바뀔 때 -> 서버에서 조회
  useEffect(() => {
    if (!date) {
      resetFields(false);
      return;
    }

    const fetchDataByDate = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}/SGNC?date=${date}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.ok) {
          // 데이터 있음 => 수정 모드, 폼 채우기
          const data = await response.json();
          setTables(data.tables ?? "");
          setVisittable(data.visittable ?? "");
          setCash(data.cash ?? "");
          setCard(data.card ?? "");
          setCoupon(data.coupon ?? "");
          setDelivery(data.delivery ?? "");
          setDeliverycount(data.deliverycount ?? "");
          setDeliverycharge(data.deliverycharge ?? "");
          setMemo(data.memo ?? "");

          setRunning(data.running ?? ""); // 이미 계산된 값 DB에 있다면
          setTotalsales(data.totalsales ?? ""); // 이미 계산된 값 DB에 있다면

          setIsEditMode(true);
        } else if (response.status === 404) {
          // 데이터 없음 => 새 입력 모드
          resetFields(false);
        } else {
          // 기타 오류
          const errData = await response.json();
          toast.error("조회 중 오류: " + errData.message);
        }
      } catch (error) {
        console.error(error);
        toast.error("서버 에러가 발생했습니다.");
      }
    };

    fetchDataByDate();
  }, [date]);

  // (4) 저장/수정 버튼
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const token = localStorage.getItem("token");
      let url = `${process.env.REACT_APP_API_ENDPOINT}/SGNC/${date}`;
      let method = "POST";

      if (isEditMode) {
        method = "PUT";
      }

      const bodyData = {
        date,
        tables: Number(tables || 0),
        visittable: Number(visittable || 0),
        running: Number(running || 0),
        cash: Number(cash || 0),
        card: Number(card || 0),
        coupon: Number(coupon || 0),
        delivery: Number(delivery || 0),
        totalsales: Number(totalsales || 0),
        deliverycount: Number(deliverycount || 0),
        deliverycharge: Number(deliverycharge || 0),
        memo,
      };

      const response = await fetch(url, {
        method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(bodyData),
      });

      const result = await response.json();

      if (response.ok && result.success) {
        if (isEditMode) {
          toast.success("수정되었습니다!");
          alert("수정되었습니다!");
        } else {
          toast.success("DB에 성공적으로 저장되었습니다!");
          alert("DB에 성공적으로 저장되었습니다!");
        }
        resetFields(false);
        onClose();
      } else {
        toast.error("오류가 발생했습니다: " + result.message);
        alert("오류가 발생했습니다: " + result.message);
      }
    } catch (error) {
      console.error(error);
      toast.error("요청 중 에러가 발생했습니다.");
      alert("요청 중 에러가 발생했습니다.");
    }
  };

  // (5) 폼 리셋
  const resetFields = (editMode = false) => {
    setTables("");
    setVisittable("");
    setCash("");
    setCard("");
    setCoupon("");
    setDelivery("");
    setDeliverycount("");
    setDeliverycharge("");
    setMemo("");
    setRunning("");
    setTotalsales("");
    setIsEditMode(editMode);
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="modal-close" onClick={onClose}>
          X
        </button>
        <h2>매출 입력 ({date})</h2>
        <div className="form-container">
          <div className="second-form">
            <div className="one-form">
              <label>테이블 수</label>
              <input
                type="number"
                pattern="[0-9]*"
                inputmode="numeric"
                value={tables}
                onChange={(e) => setTables(e.target.value)}
              />
            </div>

            <div className="one-form">
              <label>방문테이블</label>
              <input
                type="number"
                pattern="[0-9]*"
                inputmode="numeric"
                value={visittable}
                onChange={(e) => setVisittable(e.target.value)}
              />
            </div>

            <div className="one-form">
              <label>현금</label>
              <input
                type="number"
                pattern="[0-9]*"
                inputmode="numeric"
                value={cash}
                onChange={(e) => setCash(e.target.value)}
              />
            </div>

            <div className="one-form">
              <label>카드</label>
              <input
                type="number"
                pattern="[0-9]*"
                inputmode="numeric"
                value={card}
                onChange={(e) => setCard(e.target.value)}
              />
            </div>

            <div className="one-form">
              <label>쿠폰</label>
              <input
                type="number"
                pattern="[0-9]*"
                inputmode="numeric"
                value={coupon}
                onChange={(e) => setCoupon(e.target.value)}
              />
            </div>

            <div className="one-form">
              <label>배달/포장</label>
              <input
                type="number"
                pattern="[0-9]*"
                inputmode="numeric"
                value={delivery}
                onChange={(e) => setDelivery(e.target.value)}
              />
            </div>

            <div className="one-form">
              <label>배달건수</label>
              <input
                type="number"
                pattern="[0-9]*"
                inputmode="numeric"
                value={deliverycount}
                onChange={(e) => setDeliverycount(e.target.value)}
              />
            </div>

            <div className="one-form">
              <label>배달비지출</label>
              <input
                type="number"
                pattern="[0-9]*"
                inputmode="numeric"
                value={deliverycharge}
                onChange={(e) => setDeliverycharge(e.target.value)}
              />
            </div>
          </div>

          <label>메모</label>
          <textarea
            rows="3"
            value={memo}
            onChange={(e) => setMemo(e.target.value)}
          />

          <label>가동률(자동 계산)</label>
          <input type="number" readOnly value={running} />

          <label>총매출(자동 계산)</label>
          <input type="number" readOnly value={totalsales} />

          <div className="buttonDiv">
            <button
              className="submit-button"
              type="submit"
              onClick={handleSubmit}
            >
              {isEditMode ? "수정하기" : "저장"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalForm;
