// client/src/pages/MonthlyCalendar.js
import React, { useState, useEffect, useCallback } from "react";
import "./MonthlyCalendar.css";
import ProgressBar from "../components/ProgressBar";

function MonthlyCalendar({ onDateSelect, selectedTableName }) {
  const [tableName] = useState(selectedTableName);
  const [calendarData, setCalendarData] = useState([]);
  // [{ dateObj, yyyymmdd, day, isEntered, totalsales }, ...]
  const [currentMonth, setCurrentMonth] = useState(new Date());
  // 현재 월( Date 객체 ), 예: 2025-01-01
  const [goal, setGoal] = useState(0);
  const [thisMonthSum, setThisMonthSum] = useState(0);
  const [prevMonthSum, setPrevMonthSum] = useState(0);

  // "오늘인지" 판별 함수
  const isToday = (dateObj) => {
    const now = new Date();
    return (
      dateObj.getFullYear() === now.getFullYear() &&
      dateObj.getMonth() === now.getMonth() &&
      dateObj.getDate() === now.getDate()
    );
  };

  // (2) generateCalendar 함수
  const generateCalendar = useCallback(async () => {
    // ex) currentMonth = 2025-01-01
    const year = currentMonth.getFullYear();
    const month = currentMonth.getMonth(); // 0=Jan, 1=Feb ...

    // 이번 달 1일(로컬)
    const firstDayOfMonth = new Date(year, month, 1);
    // 이번 달 마지막 날 (월+1, 날짜=0 => 이전달 마지막일)
    const lastDayOfMonth = new Date(year, month + 1, 0);

    // 첫 날 요일 (일=0, 월=1, ... 토=6)
    const startDay = firstDayOfMonth.getDay();

    // 마지막 날 날짜
    const endDate = lastDayOfMonth.getDate();

    // 달력에서 몇 주가 필요한지 계산
    // ex) 총 셀이 startDay + endDate 만큼 => /7
    // 구체적 계산은 아래 로직에서 순회

    // (A) 임시 배열 생성
    const tempArray = [];

    // (B) 서버로부터 입력된 날짜 목록(해당 월) 받아오기
    // 여기서는 예시로 /pcroom1/month?year=2025&month=1
    // → [{date:'2025-01-01', totalsales: 30000}, ...]
    let enteredDates = [];
    try {
      const token = localStorage.getItem("token");
      // 예: GET /pcroom1/month?year=2025&month=1
      const res = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/${tableName}/month?year=${year}&month=${
          month + 1
        }`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await res.json();
      if (data.success) {
        // [{ date:'2025-01-01', totalsales:30000 }, ...]
        enteredDates = data.data;
      }
    } catch (error) {
      console.error(error);
    }

    // Map 형태로 빠르게 접근: { "2025-01-01": 30000, ... }
    const enteredMap = {};
    enteredDates.forEach((item) => {
      enteredMap[item.date] = item.totalsales || 0;
    });

    // (C) 달력 앞부분(이전 달 날짜)
    // startDay = 0(일) 이면 이전 달 없음, 1이면 이전달 하루, ...
    // ex) startDay=3 이면 달력 1행에서 3칸(일,월,화) 비워야 함
    for (let i = 0; i < startDay; i++) {
      tempArray.push(null);
      // null -> '이전 달' 빈칸
    }

    // (D) 이번 달 1일부터 말일까지
    for (let dateNum = 1; dateNum <= endDate; dateNum++) {
      const d = new Date(year, month, dateNum);

      const y = d.getFullYear();
      const m = String(d.getMonth() + 1).padStart(2, "0");
      const day = String(d.getDate()).padStart(2, "0");

      const yyyymmdd = `${y}-${m}-${day}`;

      // 입력된지 여부 + totalsales
      let isEntered = false;
      let daySales = 0;
      if (enteredMap[yyyymmdd] !== undefined) {
        isEntered = true;
        daySales = enteredMap[yyyymmdd];
      }

      tempArray.push({
        dateObj: d,
        yyyymmdd,
        day: d.getDay(), // 0=일,1=월,...6=토
        isEntered,
        totalsales: daySales,
      });
    }

    setCalendarData(tempArray);

    const yearmonthStr = `${year}-${String(month + 1).padStart(2, "0")}`;
    try {
      const token = localStorage.getItem("token");
      const progRes = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/progress?yearmonth=${yearmonthStr}&tableName=${tableName}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const progData = await progRes.json();
      if (progData.success) {
        setGoal(progData.goal); // 예) 10000000
        setThisMonthSum(progData.thisMonthSum); // 예) 3000000
        setPrevMonthSum(progData.prevMonthSum);
      } else {
        setGoal(0);
        setThisMonthSum(0);
        setPrevMonthSum(0);
      }
    } catch (err) {
      console.error(err);
      setGoal(0);
      setThisMonthSum(0);
      setPrevMonthSum(0);
    }
  }, [currentMonth, tableName]);

  useEffect(() => {
    // (1) 현재 월( currentMonth ) 의 1일 ~ 말일 날짜 배열 생성
    // + 이전/다음 달 일부 날짜(달력 앞뒤 공백 채우기)
    generateCalendar();
  }, [currentMonth, generateCalendar]);

  // (3) 이전달, 다음달 이동
  const goPrevMonth = () => {
    const newMonth = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth() - 1,
      1
    );
    setCurrentMonth(newMonth);
  };
  const goNextMonth = () => {
    const newMonth = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth() + 1,
      1
    );
    setCurrentMonth(newMonth);
  };

  // (4) 클릭 시 -> 부모에 날짜 전달
  const handleDateClick = (yyyymmdd) => {
    if (!yyyymmdd) return;
    onDateSelect && onDateSelect(yyyymmdd);
  };

  // (5) 현재 표시 중인 연/월 문자열
  const year = currentMonth.getFullYear();
  const month = currentMonth.getMonth() + 1;

  // (6) 렌더
  return (
    <div className="calendar-container">
      {/* 상단: 이전달 버튼, '2025년 1월', 다음달 버튼 */}
      <div className="calendar-header">
        <button onClick={goPrevMonth}>◀</button>
        <span>
          {year}년 {month}월
        </span>
        <button onClick={goNextMonth}>▶</button>
      </div>

      {/* ProgressBar (목표 vs 이달 누적) */}
      <ProgressBar
        goal={goal}
        thisMonthSum={thisMonthSum}
        prevMonthSum={prevMonthSum}
      />

      {/* 요일 헤더 (일~토) */}
      <div className="calendar-weekdays">
        <div className="weekday">일</div>
        <div className="weekday">월</div>
        <div className="weekday">화</div>
        <div className="weekday">수</div>
        <div className="weekday">목</div>
        <div className="weekday">금</div>
        <div className="weekday">토</div>
      </div>

      {/* 달력 본문 (5~6행) */}
      <div className="calendar-grid">
        {calendarData.map((item, idx) => {
          if (item === null) {
            // 이전달/빈칸
            return <div key={idx} className="calendar-cell empty"></div>;
          }

          const cellClass = item.isEntered ? "entered" : "not-entered";
          const todayClass = isToday(item.dateObj) ? "today-cell" : "";
          return (
            <div
              key={idx}
              className={`calendar-cell ${cellClass} ${todayClass}`}
              onClick={() => handleDateClick(item.yyyymmdd)}
              title={item.yyyymmdd}
            >
              {/* 날짜 숫자 */}
              <div className="date-num">
                {item.dateObj.getDate()}
                {isToday(item.dateObj) && (
                  <img
                    src="/today.png"
                    alt="오늘"
                    style={{ width: "30px", marginLeft: "4px" }}
                  />
                )}
              </div>
              {/* totalsales 표시 (입력된 날만 표시) */}
              {item.isEntered && (
                <div className="totalsales">
                  {item.totalsales.toLocaleString()}원
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default MonthlyCalendar;
