// client/src/pages/AdminUsers.js
import React, { useState, useEffect } from "react";
import "./AdminUsers.css"; // 스타일은 예시
import AddUserModal from "./AddUserModal"; // 회원추가 모달

function AdminUsers() {
  const [userList, setUserList] = useState([]);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  // 페이지 로드 시: auth 테이블에서 admin 제외한 사용자들 가져오기
  useEffect(() => {
    fetchUserList();
  }, []);

  const fetchUserList = async () => {
    try {
      const token = localStorage.getItem("token");
      const res = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/admin/users`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const data = await res.json();
      if (data.success) {
        // data.users: [{id:'abc', name:'홍길동', role:1}, ...]
        setUserList(data.users);
      } else {
        alert("회원 목록 조회 실패: " + data.message);
      }
    } catch (err) {
      console.error(err);
      alert("회원 목록 조회 에러");
    }
  };

  // 비밀번호 재설정
  const handleResetPassword = async (userId) => {
    // 궁금한 점: 새 비밀번호를 어떻게 생성할 것인가?
    // 임시비밀번호 발급 or 모달로 pw 입력받기?
    // 일단 '임시비번: 1234' 예시:
    const newPw = prompt("새 비밀번호를 입력하세요 (임시 예시)");
    if (!newPw) return;

    try {
      const token = localStorage.getItem("token");
      const res = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/admin/users/${userId}/resetpw`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ newPw }),
        }
      );
      const data = await res.json();
      if (data.success) {
        alert("비밀번호 재설정 완료");
      } else {
        alert("재설정 실패: " + data.message);
      }
    } catch (err) {
      console.error(err);
      alert("재설정 중 에러");
    }
  };

  const handleDeleteUser = async (userId) => {
    const confirmDel = window.confirm("정말 이 회원을 삭제하시겠습니까?");
    if (!confirmDel) return;

    try {
      const token = localStorage.getItem("token");
      const res = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/admin/users/${userId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await res.json();
      if (data.success) {
        alert("회원 삭제 완료");
        fetchUserList(); // 목록 재로드
      } else {
        alert("삭제 실패: " + data.message);
      }
    } catch (err) {
      console.error(err);
      alert("삭제 중 에러");
    }
  };

  // 회원추가 모달 열기
  const openAddModal = () => {
    setIsAddModalOpen(true);
  };
  // 회원추가 모달 닫기
  const closeAddModal = () => {
    setIsAddModalOpen(false);
  };

  // 회원추가 완료 후 -> 목록 리프레시
  const handleAddUserSuccess = () => {
    closeAddModal();
    fetchUserList(); // 다시 목록 불러오기
  };

  return (
    <div className="admin-users-container">
      <h2>회원관리</h2>
      <div className="admin-users-table-wrapper">
        <table className="admin-users-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>이름</th>
              <th>Role</th>
              <th>비밀번호 재설정</th>
              <th>회원삭제</th>
            </tr>
          </thead>
          <tbody>
            {userList.map((user) => (
              <tr key={user.id}>
                <td>{user.id}</td>
                <td>{user.name}</td>
                <td>{user.role}</td>
                <td>
                  <button
                    onClick={() => handleResetPassword(user.id)}
                    className="reset-btn"
                  >
                    재설정
                  </button>
                </td>
                <td>
                  <button onClick={() => handleDeleteUser(user.id)}>
                    삭제
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <button className="add-user-btn" onClick={openAddModal}>
        회원추가
      </button>

      {/* 회원추가 모달 */}
      {isAddModalOpen && (
        <AddUserModal
          onClose={closeAddModal}
          onAddSuccess={handleAddUserSuccess}
        />
      )}
    </div>
  );
}

export default AdminUsers;
