// client/src/pages/MainPage.js
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./MainPage.css"; // 스타일은 예시

function MainPage() {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  // 컴포넌트 로드 시(또는 token 변화 시) 사용자 정보를 불러옴
  useEffect(() => {
    const fetchUserInfo = async () => {
      if (!token) {
        navigate("/login");
        return;
      } // 토큰 없으면 로그인 안 된 상태 -> 패스
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}/auth/me`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        if (data.success) {
          if (data.user.role === 1) {
            navigate("/pcroom1");
          } else if (data.user.role === 2) {
            navigate("/pcroom2");
          } else if (data.user.role === 3) {
            navigate("/dessert39");
          } else if (data.user.role === 4) {
            navigate("/seoga");
          } else if (data.user.role === 5) {
            navigate("/gjtteok");
          } else if (data.user.role === 6) {
            navigate("/bodram1");
          } else if (data.user.role === 7) {
            navigate("/photoism");
          } else if (data.user.role === 49) {
            navigate("/admin");
          } else {
            navigate("/");
          }
        } else {
          console.error("사용자 정보 조회 실패:", data.message);
          localStorage.removeItem("token");
          navigate("/login");
        }
      } catch (error) {
        console.error("사용자 정보 조회 중 에러:", error);
        localStorage.removeItem("token");
        navigate("/login");
      }
    };

    fetchUserInfo();
  }, [token, navigate]);

  return (
    <div style={{ textAlign: "center", marginTop: "100px" }}>
      <h2>메인 페이지 로딩중...</h2>
      <p>잠시만 기다려 주세요.</p>
    </div>
  );
}

export default MainPage;
