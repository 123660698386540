// src/pages/LoginPage.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./LoginPage.css";

function LoginPage({ onLoginSuccess }) {
  const [loginId, setLoginId] = useState("");
  const [pw, setPw] = useState("");
  const [role, setRole] = useState(null);

  function calDate() {
    const d = new Date();

    const y = d.getFullYear();
    const m = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");

    return `${y}-${m}-${day}`;
  }

  const today = calDate();

  const navigate = useNavigate();

  const checkRole = () => {
    if (role === 1) {
      navigate("/pcroom1");
    } else if (role === 2) {
      navigate("/pcroom2");
    } else if (role === 3) {
      navigate("/dessert39");
    } else if (role === 4) {
      navigate("/seoga");
    } else if (role === 5) {
      navigate("/gjtteok");
    } else if (role === 6) {
      navigate("/bodram1");
    } else if (role === 7) {
      navigate("/photoism");
    } else if (role === 49) {
      navigate("/admin");
    }
  };

  checkRole();

  const handleLogin = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/auth/login`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ id: loginId, pw: pw }),
        }
      );
      const data = await response.json();
      if (data.success) {
        alert("로그인 성공");
        localStorage.setItem("token", data.token);

        onLoginSuccess && onLoginSuccess(data.user);
        setRole(data.role);
      } else {
        alert(data.message);
      }
    } catch (error) {
      console.error(error);
      alert("로그인 요청 중 에러 발생");
    }
  };

  return (
    <div className="firstcontainer">
      <div className="login-container">
        <h2>HARMONY ONLINE</h2>
        <div style={{ width: "100%" }}>
          <h4 style={{ textAlign: "right" }}>{today}</h4>
        </div>

        <div className="input-group">
          {/* <label htmlFor="loginId">아이디</label> */}
          <input
            id="loginId"
            type="text"
            value={loginId}
            onChange={(e) => setLoginId(e.target.value)}
            placeholder="아이디"
          />
        </div>

        <div className="input-group">
          {/* <label htmlFor="pw">비밀번호</label> */}
          <input
            id="pw"
            type="password"
            value={pw}
            onChange={(e) => setPw(e.target.value)}
            placeholder="비밀번호"
          />
        </div>

        <button className="login-button" onClick={handleLogin}>
          로그인
        </button>

        {/* <div className="register-link">
        <p>아직 회원이 아니신가요?</p>
        <Link to="/register">회원가입 하기</Link>
      </div> */}
      </div>
    </div>
  );
}

export default LoginPage;
