// client/src/pages/MainPage.js
import React, { useEffect, useState } from "react";
import { useNavigate, Routes, Route } from "react-router-dom";
import "./Admin.css"; // 스타일은 예시
import Navbar from "../components/Navbar";
import AdminSearch from "../components/AdminSearch";
import AdminStatic from "../components/AdminStatic";
import AdminUsers from "../components/AdminUsers";

function Admin() {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  // 서버에서 받아온 userName을 저장할 state
  const [userName, setUserName] = useState("");

  // 컴포넌트 로드 시(또는 token 변화 시) 사용자 정보를 불러옴
  useEffect(() => {
    const fetchUserInfo = async () => {
      if (!token) {
        navigate("/login");
        return;
      } // 토큰 없으면 로그인 안 된 상태 -> 패스
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}/auth/me`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        if (data.success) {
          // 사용자 이름 추출
          setUserName(data.user.name);
        } else {
          console.error("사용자 정보 조회 실패:", data.message);
          localStorage.removeItem("token");
        }
      } catch (error) {
        console.error("사용자 정보 조회 중 에러:", error);
      }
    };

    fetchUserInfo();
  }, [token, navigate]);

  const handleLogout = () => {
    // 로컬스토리지의 토큰 제거
    localStorage.removeItem("token");
    navigate("/login");
  };

  // const handleLogin = () => {
  //   navigate("/login");
  // };

  return (
    <div className="main-container">
      <img src="/harmony_logo.png" className="logo" alt="harmony_logo"></img>
      <div className="admin-menu-container">
        <Navbar />
      </div>
      <div className="header">
        {/* 로그인되어 있을 때만 로그아웃 버튼 표시 */}
        {token && (
          <div className="right-area">
            {userName && <span className="user-name">{userName}님</span>}
            <button className="logout-button" onClick={handleLogout}>
              <img src="/logout.svg" alt="logout_button"></img>
              <span>로그아웃</span>
            </button>
          </div>
        )}
        {/* {!token && (
          <div className="right-area">
            <button className="logout-button" onClick={handleLogin}>
              <img src="/logout.svg" alt="login_button"></img>
              <span>로그인</span>
            </button>
          </div>
        )} */}
      </div>
      <div>
        <Routes>
          <Route path="sales" element={<AdminSearch />} />
          <Route path="users" element={<AdminUsers />} />
          <Route index element={<AdminStatic />} />
        </Routes>

        {/* <AdminSearch /> */}
      </div>
      <div className="store-button-container">
        {/* 7개 매장 버튼 */}
        {/* <Link to="/dessert39" className="store-button dessert39">
          디저트39
        </Link>
        <Link to="/pcroom1" className="store-button pcroom1">
          아이센스리그 전주
        </Link>
        <Link to="/pcroom2" className="store-button pcroom2">
          아이센스리그 양주
        </Link>
        <Link to="/seoga" className="store-button seoga">
          서가앤쿡
        </Link>
        <Link to="/gjtteok" className="store-button gjtteok">
          걸작떡볶이치킨
        </Link>
        <Link to="/bodram1" className="store-button bodram1">
          보드람치킨
        </Link>
        <Link to="/photoism" className="store-button photoism">
          포토이즘박스 양주
        </Link> */}
      </div>
    </div>
  );
}

export default Admin;
