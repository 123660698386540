// client/src/components/AddUserModal.js
import React, { useState } from "react";
import "./AddUserModal.css";

function AddUserModal({ onClose, onAddSuccess }) {
  const [userId, setUserId] = useState("");
  const [pw, setPw] = useState("");
  const [pwConfirm, setPwConfirm] = useState("");
  const [name, setName] = useState("");
  const [role, setRole] = useState(""); // or default "0"

  const handleAddUser = async () => {
    if (!userId || !pw || !pwConfirm || !name || !role) {
      alert("모든 필드를 입력하세요");
      return;
    }
    if (pw !== pwConfirm) {
      alert("비밀번호 확인이 일치하지 않습니다.");
      return;
    }

    try {
      const token = localStorage.getItem("token");
      const res = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/admin/users`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            id: userId,
            pw: pw,
            name: name,
            role: Number(role),
          }),
        }
      );
      const data = await res.json();
      if (data.success) {
        alert("회원 추가 성공");
        onAddSuccess && onAddSuccess();
      } else {
        alert("회원 추가 실패: " + data.message);
      }
    } catch (err) {
      console.error(err);
      alert("추가 중 에러");
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h3>회원추가</h3>
        <label>ID</label>
        <input
          type="text"
          value={userId}
          onChange={(e) => setUserId(e.target.value)}
        />

        <label>비밀번호</label>
        <input
          type="password"
          value={pw}
          onChange={(e) => setPw(e.target.value)}
        />

        <label>비밀번호 확인</label>
        <input
          type="password"
          value={pwConfirm}
          onChange={(e) => setPwConfirm(e.target.value)}
        />

        <label>이름</label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />

        <label>Role</label>
        <input
          type="number"
          value={role}
          onChange={(e) => setRole(e.target.value)}
        />
        {/* 실무에선 <select> 로 0=일반, 1=??? 식 하거나, 
            role 목록을 map(...) 해서 <option>들로 구성할 수도 있음. */}

        <div className="button-row">
          <button onClick={handleAddUser}>확인</button>
          <button onClick={onClose}>취소</button>
        </div>
      </div>
    </div>
  );
}

export default AddUserModal;
