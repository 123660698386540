// client/src/App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import MainPage from "./pages/MainPage";
import Dessert39Page from "./pages/Dessert39Page";
import Pcroom1 from "./pages/Pcroom1";
import Pcroom2 from "./pages/Pcroom2";
import Seoga from "./pages/Seoga";
import Gjtteok from "./pages/Gjtteok";
import Bodram1 from "./pages/Bodram1";
import Photoism from "./pages/Photoism";
import LoginPage from "./pages/LoginPage";
// import RegisterPage from "./pages/RegisterPage";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import Admin from "./pages/Admin";

function App() {
    return (
        <Router>
            <Routes>
                {/* 메인 페이지 */}
                <Route path="/" element={<MainPage />} />
                {/* 관리자 페이지 */}
                <Route
                    path="/admin/*"
                    element={
                        <PrivateRoute requiredRole={49}>
                            <Admin />
                        </PrivateRoute>
                    }
                />

                {/* 로그인 페이지 */}
                <Route
                    path="/login"
                    element={
                        <PublicRoute>
                            <LoginPage />
                        </PublicRoute>
                    }
                />

                {/* 회원가입 페이지
        <Route
          path="/register"
          element={
            <PublicRoute>
              <RegisterPage />
            </PublicRoute>
          }
        /> */}

                {/* 디저트39 페이지 */}
                <Route
                    path="/dessert39"
                    element={
                        <PrivateRoute requiredRole={3}>
                            <Dessert39Page />
                        </PrivateRoute>
                    }
                />

                {/* 아이센스리그 전주 */}
                <Route
                    path="/pcroom1"
                    element={
                        <PrivateRoute requiredRole={1}>
                            <Pcroom1 />
                        </PrivateRoute>
                    }
                />

                {/* 아이센스리그 양주 */}
                <Route
                    path="/pcroom2"
                    element={
                        <PrivateRoute requiredRole={2}>
                            <Pcroom2 />
                        </PrivateRoute>
                    }
                />

                {/* 서가앤쿡 */}
                <Route
                    path="/seoga"
                    element={
                        <PrivateRoute requiredRole={4}>
                            <Seoga />
                        </PrivateRoute>
                    }
                />

                {/* 걸작떡볶이 */}
                <Route
                    path="/gjtteok"
                    element={
                        <PrivateRoute requiredRole={5}>
                            <Gjtteok />
                        </PrivateRoute>
                    }
                />

                {/* 보드람치킨 */}
                <Route
                    path="/bodram1"
                    element={
                        <PrivateRoute requiredRole={6}>
                            <Bodram1 />
                        </PrivateRoute>
                    }
                />

                {/* 포토이즘 */}
                <Route
                    path="/photoism"
                    element={
                        <PrivateRoute requiredRole={7}>
                            <Photoism />
                        </PrivateRoute>
                    }
                />

                {/* <Route path="/reg" element={<RegisterPage />} /> */}
            </Routes>
        </Router>
    );
}

export default App;
